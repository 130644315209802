<template>
  <div id="app">
    <Generator/>
  </div>
</template>

<script>
import Generator from './components/Generator.vue'

export default {
  name: 'App',
  components: {
    Generator
  },
}
</script>

<style>
  html {
    padding: 50px;
    color: #fff;
    font: .875em/1.77778 Manrope,sans-serif;
  }

  body {
    background: #01152b;
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
  }

  h4 {
    margin: 0 0 10px;
  }

  p {
    margin: 0;
  }

  a {
    color: #0cbcfa;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  label {
    font-weight: bold;
  }

  input[type="text"],
  select {
    padding: 5px;
  }

  .field {
    margin-bottom: 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .required {
    color: #0cbcfa;
  }

  .image {
    max-width: 500px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .instructions {
    background: #fbfbfb;
    padding: 20px;
    margin-left: 40px;
    color: #01152b;
    align-self: flex-start;
    border-radius: 5px;
  }

  .instructions ol {
    padding-left: 10px;
    margin-bottom: 0;
  }

  .instructions span {
    background:  #01152b;
    display: inline-block;
    border-radius: 6px;
    color: #fff;
    font-size: 10px;
    padding: 3px 5px;
  }

  .btn {
    background: #0cbcfa;
    color: #fff;
    border-radius: 5px;
    padding: 12px 20px;
    display: inline-block;
    font-size: .875rem;
    font-weight: 700;
    line-height: 16px;
    outline: 0;
    border: none;
    opacity: 1;
    max-width: 250px;
    transition: all .3s ease-in-out;
  }

  .btn:hover {
    cursor: pointer;
    background: #049acf;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .preview {
    background-color: #fff;
    padding: 40px 50px 20px 50px;
    margin-top: 50px;
  }

  .output-container {
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  .actions {
    margin-top: 15px;
    position: relative;
  }

  .prompt {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    padding: 10px 15px;
    background:#01152b;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease-in-out;
  }

  .actions .btn {
    border-radius: 0;
    margin-right: 15px;
  }

  .visible {
    opacity: 1;
  }
</style>
