<template>
  <div class="container">
    <div class="generator">
      <Logo />

      <div class="content">
        <div class="form">
          <div class="fields">
            <div class="field">
              <label for="name">Employee <span class="required">*</span></label>
              <select v-model="data.employee" id="employee" ref="employeeSelect" @change="generatePreview">
                <option :value="{}" disabled selected hidden>Select employee</option>
                <option
                  v-for="(employee, index) in employees"
                  :key="index"
                  :value="{
                    name: employee.name,
                    role: employee.role,
                    phone: employee.phone,
                    email: employee.email,
                    image: `${publicPath}assets/profile-images/${employee.name}.png`
                  }"
                >
                  {{ employee.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="fields">
            <div class="field">
              <label for="twitter">Twitter</label>
              <input v-model="data.twitter" id="twitter" type="text" placeholder="Enter Twitter URL here">
            </div>

            <div class="field">
              <label for="linkedin">LinkedIn</label>
              <input v-model="data.linkedin" id="linkedin" type="text" placeholder="Enter LinkedIn URL here">
            </div>
          </div>

          <i>Twitter & LinkedIn urls default to 43 social pages, use these fields to override to something else</i>
          <br>
        </div>

        <div class="instructions">
          <h4>Generate a signature!</h4>

          <ol>
            <li>Select yourself from the employee list</li>
            <li>Copy for Gmail</li>
            <li>Inside gmail hit the cog (top right)</li>
            <li>Click See all settings</li>
            <li>Scroll down to 'Signature'</li>
            <li>inside the signature text editor <br /> <span>ctrl + a</span> or <span>cmd + a</span></li>
            <li>inside the signature text editor <br /> <span>ctrl + v</span> or <span>cmd + v</span> </li>
            <li>Hit save!</li>
          </ol>
        </div>
      </div>
    </div>

    <div :class="[preview ? 'visible output-container' : 'output-container']">
      <div class="preview">
        <Output :data="data" ref="output" :publicPath="publicPath" />

        <textarea
          v-model="this.html"
          ref="html"
          style="opacity: 0"
        />
      </div>

      <div class="actions">
        <button @click="copyHTML" class="btn">Copy HTML</button>
        <button @click="copySelect" class="btn">Copy for Gmail</button>

        <Prompt :class="[prompt ? 'visible' : '']" />
      </div>
    </div>
  </div>
</template>

<script>
import Output from './Output.vue'
import Prompt from './Prompt.vue'

import Logo from '../assets/logo.svg'

// Import employees array from json
import employeesArray from './json/employees.json'

export default {
  name: 'Generator',
  components: { Output, Logo, Prompt },
  data: () => {
    // Set public path based on current build environment
    const publicPath = process.env.VUE_APP_BASE_URL

    // Sort the employees array in alphabetical order
    const employees = employeesArray.employees.sort()

    return {
      employees,
      publicPath,
      preview: false,
      prompt: false,
      data: {
        employee: {},
        linkedin: '',
        twitter: ''
      },
      html: ''
    }
  },
  watch: {
    prompt: function(oldVal, newVal) {
      console.log('watching', oldVal, newVal)
      if (!this.prompt) return

      setTimeout(() => {
        this.prompt = false
      }, 1000)
    }
  },
  methods: {
    generatePreview () {
      if (Object.keys(this.data.employee).length === 0) return

      this.preview = true
      this.html = this.parseHTML()
    },
    parseHTML () {
      return this.$refs.output.$el.querySelector('table').outerHTML.replace(/<!---->/g, '')
    },
    copyHTML () {
      const html = this.$refs.output.$el.innerHTML
      console.log(html)
      navigator.clipboard.writeText(html)
      .then(() => {
        this.prompt = true
      })
    },
    copySelect () {
      if (!window.getSelection) return

      let range = document.createRange()
      range.selectNode(this.$refs.output.$el.querySelector('table'))
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.prompt = true
    }
  }
}
</script>
