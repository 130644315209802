var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"generator"},[_c('Logo'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"fields"},[_c('div',{staticClass:"field"},[_vm._m(0),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.employee),expression:"data.employee"}],ref:"employeeSelect",attrs:{"id":"employee"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "employee", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.generatePreview]}},[_c('option',{attrs:{"disabled":"","selected":"","hidden":""},domProps:{"value":{}}},[_vm._v("Select employee")]),_vm._l((_vm.employees),function(employee,index){return _c('option',{key:index,domProps:{"value":{
                  name: employee.name,
                  role: employee.role,
                  phone: employee.phone,
                  email: employee.email,
                  image: `${_vm.publicPath}assets/profile-images/${employee.name}.png`
                }}},[_vm._v(" "+_vm._s(employee.name)+" ")])})],2)])]),_c('div',{staticClass:"fields"},[_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":"twitter"}},[_vm._v("Twitter")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.twitter),expression:"data.twitter"}],attrs:{"id":"twitter","type":"text","placeholder":"Enter Twitter URL here"},domProps:{"value":(_vm.data.twitter)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "twitter", $event.target.value)}}})]),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":"linkedin"}},[_vm._v("LinkedIn")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.linkedin),expression:"data.linkedin"}],attrs:{"id":"linkedin","type":"text","placeholder":"Enter LinkedIn URL here"},domProps:{"value":(_vm.data.linkedin)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "linkedin", $event.target.value)}}})])]),_c('i',[_vm._v("Twitter & LinkedIn urls default to 43 social pages, use these fields to override to something else")]),_c('br')]),_vm._m(1)])],1),_c('div',{class:[_vm.preview ? 'visible output-container' : 'output-container']},[_c('div',{staticClass:"preview"},[_c('Output',{ref:"output",attrs:{"data":_vm.data,"publicPath":_vm.publicPath}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(this.html),expression:"this.html"}],ref:"html",staticStyle:{"opacity":"0"},domProps:{"value":(this.html)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(this, "html", $event.target.value)}}})],1),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn",on:{"click":_vm.copyHTML}},[_vm._v("Copy HTML")]),_c('button',{staticClass:"btn",on:{"click":_vm.copySelect}},[_vm._v("Copy for Gmail")]),_c('Prompt',{class:[_vm.prompt ? 'visible' : '']})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_vm._v("Employee "),_c('span',{staticClass:"required"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instructions"},[_c('h4',[_vm._v("Generate a signature!")]),_c('ol',[_c('li',[_vm._v("Select yourself from the employee list")]),_c('li',[_vm._v("Copy for Gmail")]),_c('li',[_vm._v("Inside gmail hit the cog (top right)")]),_c('li',[_vm._v("Click See all settings")]),_c('li',[_vm._v("Scroll down to 'Signature'")]),_c('li',[_vm._v("inside the signature text editor "),_c('br'),_vm._v(" "),_c('span',[_vm._v("ctrl + a")]),_vm._v(" or "),_c('span',[_vm._v("cmd + a")])]),_c('li',[_vm._v("inside the signature text editor "),_c('br'),_vm._v(" "),_c('span',[_vm._v("ctrl + v")]),_vm._v(" or "),_c('span',[_vm._v("cmd + v")])]),_c('li',[_vm._v("Hit save!")])])])
}]

export { render, staticRenderFns }