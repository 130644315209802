<template>
  <div class="output">
    <table
      cellpadding="0"
      cellspacing="0"
      class="sig"
      style="vertical-align: -webkit-baseline-middle;font-size: medium;font-family: Arial;background-color: #051E39; border-radius: 20px; line-height: 0;"
    >
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <img
              :src="data.employee.image"
              style="max-width: 175px; overflow: hidden; border-radius: 20px 0 0 20px;"
              height="222"
            />
          </td>

          <td width="20" style="display: block;">{{'\u00a0'}}</td>

          <td width="385">
            <table
              cellpadding="0"
              cellspacing="0"
              style="font-family: Arial"
            >
              <tbody>
                <tr>
                  <td colspan="3" height="20">{{'\u00a0'}}</td>
                </tr>

                <tr>
                  <td colspan="2" width="385">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <h3
                              color="#000000"
                              style="margin: 0px; font-size: 20px; color: #0099D0; line-height: 20px;">
                              {{data.employee.name}}
                            </h3>
                          </td>
                        </tr>

                        <tr>
                          <td height="10">{{'\u00a0'}}</td>
                        </tr>

                        <tr>
                          <td>
                            <p color="#000000" style="margin: 0px; color: #ffffff; font-size: 12px; line-height: 12px;">
                              {{data.employee.role}}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td height="10">{{'\u00a0'}}</td>
                        </tr>

                        <tr>
                          <td>
                            <p color="#000000" style="margin: 0px;color: #ffffff; font-size: 12px;line-height: 12px;">
                              43 Clicks North Ltd
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td colspan="1" width="36" style="vertical-align: top;">
                    <img width="36" :src="`${publicPath}assets/43CN-logo.png`" />
                  </td>
                </tr>

                <tr>
                  <td colspan="3" height="15"></td>
                </tr>

                <tr>
                  <td colspan="3" style="vertical-align: middle">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style="vertical-align: -webkit-baseline-middle; font-family: Arial;"
                    >
                      <tbody>
                        <tr style="vertical-align: middle; line-height: 12px;">
                          <td width="25" style="vertical-align: middle">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style="vertical-align: -webkit-baseline-middle;font-family: Arial;"
                            >
                              <tbody>
                                <tr>
                                  <td align="center" width="12" style="vertical-align: middle;">
                                    <img
                                      :src="`${publicPath}assets/phone.png`"
                                      color="#0CBCFA"
                                      width="12"
                                      style="display: block"
                                    />
                                  </td>

                                  <td width="10" style="display: block;">{{'\u00a0'}}</td>

                                  <td style="padding: 0px; color: #ffffff; text-decoration: none;">
                                    <a :href="`tel:${data.employee.phone}`" color="#ffffff" style="color: #ffffff; font-size: 12px; line-height: 12px; text-decoration: none;" target="_blank">{{data.employee.phone}}</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td height="10" style="display: block;">{{'\u00a0'}}</td>
                        </tr>

                        <tr style="vertical-align: middle; line-height: 12px;">
                          <td width="25" style="vertical-align: middle">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style="vertical-align: -webkit-baseline-middle; font-family: Arial;"
                            >
                              <tbody>
                                <tr>
                                  <td align="center" width="12" style="vertical-align: middle;">
                                    <img
                                      :src="`${publicPath}assets/email.png`"
                                      color="#0CBCFA"
                                      width="13"
                                      style="display: block"
                                    />
                                  </td>

                                  <td width="10" style="display: block;">{{'\u00a0'}}</td>

                                  <td style="padding: 0px">
                                    <a :href="`mailto:${data.employee.email}`" color="#ffffff" style="color: #ffffff; font-size: 12px; line-height: 12px;" target="_blank">{{data.employee.email}}</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td height="10" style="display: block;">{{'\u00a0'}}</td>
                        </tr>

                        <tr style="vertical-align: middle; line-height: 12px;">
                          <td width="25" style="vertical-align: middle">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style="vertical-align: -webkit-baseline-middle;font-family: Arial;"
                            >
                              <tbody>
                                <tr>
                                  <td align="center" width="12" style="vertical-align: middle;">
                                    <img
                                      :src="`${publicPath}assets/link.png`"
                                      color="#0CBCFA"
                                      width="6"
                                      style="display: block"
                                    />
                                  </td>

                                  <td width="10" style="display: block;">{{'\u00a0'}}</td>

                                  <td style="padding: 0px">
                                    <a href="https://www.43clicksnorth.co.uk/" color="#ffffff" style="color: #ffffff; font-size: 12px; line-height: 12px;" target="_blank">43clicksnorth.co.uk</a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td colspan="3" height="20" style="display: block;">{{'\u00a0'}}</td>
                </tr>

                <tr style="line-height: 18px;">
                  <td colspan="3">
                    <table cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td style="color: rgb(0, 0, 0); font-family: Arial">
                            <a
                              :href="data.twitter || 'https://twitter.com/43clicksnorth'"
                              color="#0CBCFA"
                              style="display: inline-block;padding: 0px;background-color: rgb(12, 188, 250);"
                              target="_blank"
                            >
                              <img
                                :src="`${publicPath}assets/twitter.png`"
                                alt="twitter"
                                color="#0CBCFA"
                                width="18"
                                style="display: block"
                              />
                            </a>
                          </td>

                          <td width="10" style="display: block;">{{'\u00a0'}}</td>

                          <td style="color: rgb(0, 0, 0); font-family: Arial">
                            <a
                              :href="data.linkedin || 'https://www.linkedin.com/company/43-clicks-north'"
                              color="#0CBCFA"
                              style="display: inline-block;padding: 0px;background-color: rgb(12, 188, 250);"
                              target="_blank"
                            >

                              <img
                                :src="`${publicPath}assets/linkedin.png`"
                                alt="linkedin"
                                color="#0CBCFA"
                                width="18"
                                style="display: block"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td colspan="3" height="20">{{'\u00a0'}}</td>
                </tr>
              </tbody>
            </table>
          </td>

          <td width="20" style="display: block;">{{'\u00a0'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'Output',
    props: [ 'data', 'publicPath' ]
  }
</script>
