<template>
  <div class="prompt">
    <p>Successfully copied to clipboard</p>
  </div>
</template>

<script>

export default {
  name: 'Prompt'
}
</script>
